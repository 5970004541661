@keyframes flashRed {
  0% {
    color: red;
  }
  50% {
    color: white;
  }
  100% {
    color: red;
  }
}
